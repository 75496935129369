import Base from '@/libs/core/Base'

export const fields = {
  name: '',
  resource_uid: '',
}

export default class ValueChain extends Base {
  constructor () {
    super(fields)
  }
  
  store () {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await this.form.submit('post', 'silo/value-chains', this.getFields())
        this.setFields(fields)
        flash(response)
        resolve(response)
      } catch (err) {
        reject(err)
      }
    })
  }
}