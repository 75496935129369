<template>
    <base-dialog-form
        :dialog="dialog"
        :loading="loading"
        title="Create Value Chain"
        actionText="Save details"
        @close="$emit('close')"
        @submit="submit()"
    >
        <template #content>
        <v-text-field
            dense
            outlined
            persistent-hint
            label="Name *"
            class="rounded-lg"
            v-model="valueChainObj.name"
            :hint="errors.get('name')"
            :error="errors.has('name')"
            @input="errors.clear('name')"
        ></v-text-field>

        </template>
    </base-dialog-form>
</template>
  
<script>
  import { mapActions, mapGetters } from 'vuex'
  import ValueChain from '@/libs/silo/ValueChain'
  
  export default {
    props: {
      dialog: {
        required: true,
        type: Boolean,
      },
  
      resourceUid: {
        type: String,
        required: true
      }
    },
  
    data () {
      return {
        loading: false,
        valueChainObj: new ValueChain(),
      }
    },

    computed: {
    ...mapGetters({
      resources: 'getFarmResources',
    }),

    errors () {
        return this.valueChainObj.form.errors
      },
  },
  
    methods: {
      ...mapActions([
        'setFarmResources',
      ]),
      submit () {
      if (!this.loading) {
        this.loading = true
        
        this.valueChainObj.resource_uid = this.resourceUid

        this.valueChainObj.store().then(() => {
          this.setFarmResources().then(()=> {
            this.$emit('close')
          })
        })
        .finally(() => {
          this.loading = false
        })
      }
    },
  },
  
    mounted () {
      this.setFarmResources();
    }
  }
  </script>